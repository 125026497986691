var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed z-40 flex items-center justify-center shadow-lg top-16",style:(_vm.computedStyles)},[_c('div',{staticClass:"fixed inset-0 bg-black opacity-50",on:{"click":function($event){return _vm.cancelExpandedSearch()}}}),_c('div',{staticClass:"relative w-full overflow-y-auto scrolling-touch",staticStyle:{"border-radius":"1.5rem"}},[_c('div',{staticClass:"p-2 text-gray-900 bg-white"},[_c('div',{staticClass:"pt-4"},[_vm._l((_vm.advancedFilters),function(ref,index){
var component = ref.component;
return [_c(component,{key:("advanced-filter-" + index),tag:"component",on:{"update":function (ref) {
	var hasError = ref.hasError;
	var value = ref.value;

	return _vm.onFilterUpdate(index, hasError, value);
}}})]})],2),_c('AdditionalSearchFieldsLayout',[_vm._l((_vm.additionalSearchCriteria),function(additionalFilter,index){return [_c(additionalFilter,{key:("additionalFilter-" + index),tag:"component",staticClass:"md:w-full lg:flex-1",class:("" + (index > 0 ? 'lg:ml-6 lg:mt-0 md:mt-4' : ''))})]})],2),_c('div',{staticClass:"flex flex-row justify-between px-6 pt-4 pb-2 -mx-2 border-t border-solid"},[_c('div',{staticClass:"-mx-4"},[_c('ui-button',{attrs:{"color":"white"},nativeOn:{"click":function($event){return _vm.cancelExpandedSearch()}}},[_vm._v("Abbrechen")])],1),_c('ui-button',{attrs:{"disabled":_vm.isSearchButtonDisabled},nativeOn:{"click":function($event){return _vm.search()}}},[_vm._v("Suchen")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }