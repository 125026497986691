<template>
  <div class="flex flex-row w-full px-4 pb-4" style="min-height: 3rem;">
    <div class="flex items-baseline flex-grow-0 flex-shrink-0 w-56">
      <span class="flex font-medium text-gray-900 text-small">
        <slot name="label" />
        <!-- <slot name="tooltip" /> -->
      </span>
    </div>
    <div class="flex w-full">
      <div class="flex items-baseline flex-grow">
        <slot />
      </div>
      <ui-transition-opacity>
        <ui-error-message
          v-if="shouldDisplayErrorMessage"
          class="pt-1 pl-2 pr-3 mt-4 pb-half"
        >
          <slot name="error" />
        </ui-error-message>
      </ui-transition-opacity>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@/composition-api";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import UiErrorMessage from "@/components/ui/UiErrorMessage.vue";

export default defineComponent({
  components: { UiTransitionOpacity, UiErrorMessage },
  props: {
    shouldDisplayErrorMessage: {
      type: Boolean,
      default: false
    }
  }
});
</script>
