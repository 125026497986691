







































import { computed, defineComponent, ref, watch } from "@/composition-api";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiCheckBox from "@/components/ui/UiCheckBox.vue";
import UiIconInfo from "@/assets/vectors/icons/info.svg?inline";
import { FILTER_FULLTEXT_FIELDS_CONFIG } from "./advancedFilters.config";
import AdvancedFilterLayout from "./AdvancedFilterLayout.vue";
import useFilterSearchFields from "./useFilterSearchFields";
import { getCheckedValues } from "./advancedFilters.utils";
import useMatomoEvent from "@/composition/useMatomoEvent";

export default defineComponent({
  components: { UiTooltip, UiIconInfo, UiCheckBox, AdvancedFilterLayout },
  setup(_, { emit }) {
    const {
      fullTextFieldsFilterRules,
      fullTextHasFilteredFields,
      isDisabled,
      getIsFieldValueInitiallyChecked,
      updateFullTextFilterRules
    } = useFilterSearchFields();

    const fullTextSearchFields = ref(
      // Initialise fields from config
      FILTER_FULLTEXT_FIELDS_CONFIG.map(({ id, label, value }) => ({
        id,
        label,
        value,
        checked: getIsFieldValueInitiallyChecked(value)
      }))
    );

    const checkedValues = computed(() => {
      return getCheckedValues(fullTextSearchFields.value);
    });

    const shouldRemoveFieldValues = computed(
      // Business logic: if all fields are checked, "fields" are removed
      () => fullTextHasFilteredFields.value && !checkedValues.value.length
    );

    const updateFilterRules = () => {
      const updatedFilterRules = fullTextFieldsFilterRules.value.map(
        filterRule => {
          const newFilterRule: FilterRules.FilterRule = {
            ...filterRule,
            fields: checkedValues.value
          };

          if (shouldRemoveFieldValues.value) delete newFilterRule.fields;

          return newFilterRule;
        }
      );

      updateFullTextFilterRules({ updatedFilterRules });
    };

    const shouldDisplayErrorMessage = computed(() => {
      const hasError = !fullTextSearchFields.value.some(
        ({ checked }) => checked
      );
      emit("update", { hasError, value: checkedValues.value });

      return hasError;
    });

    // When fields are checked/unchecked
    watch(
      fullTextSearchFields,
      () => {
        const shouldUpdateFilterRules =
          shouldRemoveFieldValues.value || checkedValues.value.length;

        if (shouldUpdateFilterRules) updateFilterRules();
      },
      { deep: true, immediate: true }
    );

    const trackEvent = value => {
      useMatomoEvent({
        category: "Advanced Search",
        action: `Click ${value}`,
        name: "Change WörterSuchenIn"
      });
    };

    return {
      fullTextSearchFields,
      isDisabled,
      shouldDisplayErrorMessage,
      trackEvent
    };
  }
});
