import { ComputedRef } from "@/composition-api";
import store from "@/store";
import { SERVICE_CODE_MAP } from "@/components/nav/config/index";

export default () => {
  const getIsAudioServiceInitiallyChecked = (serviceCode: string) => {
    const audioServiceFilterRule = store.state.filterRules.filterRules.find(
      ({ type }) => type === "FILTER_SERVICE"
    );

    if (!audioServiceFilterRule) {
      return true;
    }

    const { values }: { values: string[] } = audioServiceFilterRule;
    const isChecked = values.some(
      (uri: string) => uri === SERVICE_CODE_MAP[serviceCode]?.uri
    );

    return isChecked;
  };

  const updateAudioServiceFilterRule = (
    checkedValues: ComputedRef<string[]>
  ) => {
    const updatedFilterRules = checkedValues.value.length
      ? [
          {
            type: "FILTER_SERVICE",
            values: checkedValues.value.map(
              (serviceCode: string) => SERVICE_CODE_MAP[serviceCode]?.uri
            )
          }
        ]
      : [];

    store.commit("filterRules/setFilterRulesByType", {
      type: "FILTER_SERVICE",
      filterRules: updatedFilterRules
    });
  };

  return { getIsAudioServiceInitiallyChecked, updateAudioServiceFilterRule };
};
