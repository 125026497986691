<template>
  <div class="relative mr-4">
    <slot name="label" />
    <div class="h-8 pt-5">
      <input
        id="input-left"
        type="range"
        data-testid="leftInput"
        :min="min"
        :max="max"
        :step="step"
        :style="{ 'z-index': leftIndex }"
        :value="value.from"
        @input="
          $emit('input', {
            from: Math.min($event.target.value, value.to),
            to: value.to
          })
        "
        @mouseover="thumbLeftHover = true"
        @mouseleave="thumbLeftHover = false"
        @mousedown="thumbLeftActive = true"
        @mouseup="thumbLeftActive = false"
      />
      <input
        id="input-right"
        type="range"
        data-testid="rightInput"
        :min="min"
        :max="max"
        :step="step"
        :style="{ 'z-index': rightIndex }"
        :value="value.to"
        @input="
          $emit('input', {
            from: value.from,
            to: Math.max($event.target.value, value.from)
          })
        "
        @mouseover="thumbRightHover = true"
        @mouseleave="thumbRightHover = false"
        @mousedown="thumbRightActive = true"
        @mouseup="thumbRightActive = false"
      />

      <div class="slider">
        <div class="track"></div>
        <div class="range" :style="{ left: left, right: right }"></div>
        <div
          data-testid="leftThumb"
          class="thumb left focus:outline-none focus-visible:outline-blue"
          :style="{ left: left, 'z-index': leftIndex }"
          :class="{ active: thumbLeftActive, hover: thumbLeftHover }"
          role="slider"
          tabindex="0"
          :aria-valuemin="min"
          :aria-valuenow="value.from"
          :aria-valuemax="max"
          :aria-labelledby="labelId"
          @keydown.left="onThumbKeydownInput({ thumb: 'left', key: 'left' })"
          @keydown.right="onThumbKeydownInput({ thumb: 'left', key: 'right' })"
        ></div>
        <div
          data-testid="rightThumb"
          class="thumb right focus:outline-none focus-visible:outline-blue"
          :style="{ right: right, 'z-index': rightIndex }"
          :class="{ active: thumbRightActive, hover: thumbRightHover }"
          role="slider"
          tabindex="0"
          :aria-valuemin="min"
          :aria-valuenow="value.to"
          :aria-valuemax="max"
          :aria-labelledby="labelId"
          @keydown.left="onThumbKeydownInput({ thumb: 'right', key: 'left' })"
          @keydown.right="onThumbKeydownInput({ thumb: 'right', key: 'right' })"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "@/composition-api";

export default defineComponent({
  props: {
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    step: {
      type: Number
    },
    value: {
      from: Number,
      to: Number
    }
  },
  data() {
    return {
      thumbLeftActive: false,
      thumbLeftHover: false,
      thumbRightActive: false,
      thumbRightHover: false
    };
  },
  setup(props, { emit, slots }) {
    const left = computed(() => {
      return `${((props.value.from - props.min) * 100) /
        (props.max - props.min)}%`;
    });
    const right = computed(() => {
      return `${100 -
        ((props.value.to - props.min) * 100) / (props.max - props.min)}%`;
    });

    const rightIndex = computed(() => {
      return Number(props.value.to) === Number(props.min) &&
        Number(props.value.from) === Number(props.min)
        ? 40
        : 30;
    });

    const leftIndex = computed(() => {
      return Number(props.value.from) === Number(props.max) &&
        Number(props.value.to) === Number(props.max)
        ? 40
        : 30;
    });

    const onThumbKeydownInput = ({ thumb, key }) => {
      let eventPayload = undefined;

      if (thumb === "left" && key === "left") {
        const targetFromValue = props.value.from - props.step;
        eventPayload = targetFromValue >= props.min && {
          from: targetFromValue,
          to: props.value.to
        };
      }

      if (thumb === "left" && key === "right") {
        const targetFromValue = props.value.from + props.step;
        eventPayload = targetFromValue < props.value.to && {
          from: targetFromValue,
          to: props.value.to
        };
      }

      if (thumb === "right" && key === "left") {
        const targetToValue = props.value.to - props.step;
        eventPayload = targetToValue > props.value.from && {
          from: props.value.from,
          to: targetToValue
        };
      }

      if (thumb === "right" && key === "right") {
        const targetToValue = props.value.to + props.step;
        eventPayload = targetToValue <= props.max && {
          from: props.value.from,
          to: targetToValue
        };
      }

      if (eventPayload) emit("input", eventPayload);
    };

    const labelId = ref("");
    onMounted(() => {
      labelId.value = slots.label?.()?.[0]?.elm?.id;
    });

    return {
      left,
      right,
      leftIndex,
      rightIndex,
      onThumbKeydownInput,
      labelId
    };
  }
});
</script>

<style lang="css" scoped>
.slider {
  @apply relative z-10 my-0 mx-2;
}

.slider > .track {
  left: -9px;
  right: -9px;
  height: 2px;
  @apply absolute z-10 .bg-gray-300 rounded bottom-0;
}

.slider > .range {
  left: 25%;
  right: 25%;
  height: 2px;
  background-color: #003edb;
  @apply absolute bottom-0 rounded z-20;
}

.slider > .thumb {
  width: 24px;
  height: 24px;
  border: 8px solid #003edb;
  @apply absolute z-30 .bg-white rounded-full shadow-none transition ease-in-out duration-200;
}

.slider > .thumb.left {
  left: 25%;
  transform: translate(-12px, -13px);
}

.slider > .thumb.right {
  right: 25%;
  transform: translate(12px, -13px);
}

.slider > .thumb.hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border: 8px solid #0030aa;
}

.slider > .thumb.active {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border: 8px solid #0030aa;
}

input[type="range"] {
  -webkit-appearance: none;
  @apply absolute pointer-events-none z-20 opacity-0 h-3 w-full;
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  @apply rounded-none border-0 cursor-pointer;
}

input[type="range"]::-moz-range-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  @apply rounded-none border-0 cursor-pointer;
}
</style>
