











































import { computed, defineComponent, ref, watch } from "@/composition-api";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiCheckBox from "@/components/ui/UiCheckBox.vue";
import UiIconInfo from "@/assets/vectors/icons/info.svg?inline";
import { FILTER_AUDIO_SERVICE_CONFIG } from "./advancedFilters.config";
import AdvancedFilterLayout from "./AdvancedFilterLayout.vue";
import { getCheckedValues } from "./advancedFilters.utils";
import useFilterAudioService from "./useFilterAudioService";
import useMatomoEvent from "@/composition/useMatomoEvent";

export default defineComponent({
  components: { UiTooltip, UiIconInfo, UiCheckBox, AdvancedFilterLayout },
  setup(_, { emit }) {
    const {
      getIsAudioServiceInitiallyChecked,
      updateAudioServiceFilterRule
    } = useFilterAudioService();

    const audioServices = ref(
      // Initialise options from config
      FILTER_AUDIO_SERVICE_CONFIG.map(({ id, label, value, icon }) => ({
        id,
        label,
        value,
        checked: getIsAudioServiceInitiallyChecked(value),
        icon
      }))
    );

    const checkedValues = computed(() => {
      return getCheckedValues(audioServices.value);
    });

    const shouldDisplayErrorMessage = computed(() => {
      const hasError = !audioServices.value.some(({ checked }) => checked);
      emit("update", { hasError, value: checkedValues.value });

      return hasError;
    });

    // When audio types are checked/unchecked
    watch(
      audioServices,
      () => {
        updateAudioServiceFilterRule(checkedValues);
      },
      { deep: true }
    );

    const trackEvent = value => {
      useMatomoEvent({
        category: "Advanced Search",
        action: `Click ${value}`,
        name: "Category"
      });
    };

    return {
      audioServices,
      shouldDisplayErrorMessage,
      trackEvent
    };
  }
});
