import AtmoIcon from "@/assets/vectors/icons/type-atmo.svg?inline";
import OTonIcon from "@/assets/vectors/icons/type-oton.svg?inline";
import InterviewIcon from "@/assets/vectors/icons/type-interview.svg?inline";
import SurveyIcon from "@/assets/vectors/icons/type-survey.svg?inline";

export const FILTER_FULLTEXT_FIELDS_CONFIG = [
  {
    id: "title",
    label: "Titel",
    value: "FIELD_HEADLINE"
  },
  {
    id: "description",
    label: "Kurz-Beschreibung",
    value: "FIELD_DESCRIPTION"
  },
  {
    id: "transcript",
    label: "Transkript",
    value: "FIELD_TRANSCRIPT"
  },
  {
    id: "keywords",
    label: "Stichwörter",
    value: "FIELD_KEYWORD"
  },
  {
    id: "speaker",
    label: "Sprecher/in",
    value: "FIELD_PERSON"
  }
];

export const FILTER_AUDIO_SERVICE_CONFIG = [
  {
    id: "o-ton",
    label: "O-Töne",
    value: "aoton",
    icon: OTonIcon
  },
  {
    id: "atmo",
    label: "Atmo",
    value: "aatmo",
    icon: AtmoIcon
  },
  {
    id: "talk-interview",
    label: "Interviews",
    value: "atalk",
    icon: InterviewIcon
  },
  {
    id: "survey",
    label: "Umfragen",
    value: "aumfrage",
    icon: SurveyIcon
  }
];
