

























































import { computed, defineComponent, ref, inject } from "@/composition-api";
import UiButton from "@/components/ui/UiButton.vue";
import UiInput from "@dpa-id-components/ui-input";
import useExpandedSearch from "@/composition/useExpandedSearch";
import useModalScrollPrevention from "@/composition/useModalScrollPrevention";
import AHSearch from "./AHSearch.vue";
import FilterSearchFields from "./AdvancedFilters/FilterSearchFields.vue";
import FilterAudioService from "./AdvancedFilters/FilterAudioService.vue";
import FilterAudioLength from "./AdvancedFilters/FilterAudioLength.vue";
import FilterDateRange from "./AdvancedFilters/FilterDateRange.vue";
import FilterLogicalAndFullText from "./AdvancedFilters/FilterLogicalAndFullText.vue";
import FilterLogicalNotFullText from "./AdvancedFilters/FilterLogicalNotFullText.vue";
import FilterLogicalOrFullText from "./AdvancedFilters/FilterLogicalOrFullText.vue";
import AdditionalSearchFieldsLayout from "./AdditionalSearchFieldsLayout.vue";
import FilterSpeakers from "./AdvancedFilters/FilterSpeakers.vue";
import useMatomoEvent from "@/composition/useMatomoEvent";

export default defineComponent({
  components: {
    AHSearch,
    UiButton,
    UiInput,
    FilterSearchFields,
    FilterAudioService,
    AdditionalSearchFieldsLayout,
    FilterDateRange,
    FilterAudioLength
  },
  props: {
    left: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    /* Compositions used */

    const { closeExpandedSearch, executeSearch } = useExpandedSearch();
    useModalScrollPrevention();

    /* Event communication with search component */

    const eventBus: Vue = inject("eventBus");

    const search = () => {
      executeSearch();
      eventBus.$emit("search-click");
      useMatomoEvent({
        category: "Search",
        action: "Advanced Search Click Search",
        name: "Search Advanced Search"
      });
    };

    const cancelExpandedSearch = () => {
      closeExpandedSearch({ isCancelled: true });
      eventBus.$emit("cancel-search");
    };

    /* Advanced search for full text fields */

    const advancedFilters = ref([
      {
        component: FilterAudioService,
        hasError: false,
        value: []
      },
      {
        component: FilterLogicalAndFullText,
        hasError: false,
        value: ""
      },
      {
        component: FilterLogicalOrFullText,
        hasError: false,
        value: []
      },
      {
        component: FilterLogicalNotFullText,
        hasError: false,
        value: ""
      },
      {
        component: FilterSearchFields,
        hasError: false,
        value: []
      }
    ]);

    const isSearchButtonDisabled = computed(() =>
      advancedFilters.value.some(({ hasError }) => hasError)
    );

    const onFilterUpdate = (index: number, hasError: boolean, value: any[]) => {
      advancedFilters.value[index].hasError = hasError;
      advancedFilters.value[index].value = value;
    };

    /* Additional filter components */

    const additionalSearchCriteria = [
      FilterDateRange,
      FilterSpeakers,
      FilterAudioLength
    ];

    /* Computed styles */

    const computedStyles = computed(() => ({
      left: `${props.left}px`,
      width: `${props.width}px`,
      "border-radius": "1.5rem"
    }));

    return {
      additionalSearchCriteria,
      advancedFilters,
      cancelExpandedSearch,
      closeExpandedSearch,
      computedStyles,
      isSearchButtonDisabled,
      onFilterUpdate,
      search
    };
  }
});
