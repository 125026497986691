













import { computed, defineComponent } from "@/composition-api";
import { addDays, setHours, subMilliseconds } from "date-fns";
import store from "@/store";
import UiInput from "@dpa-id-components/ui-input";
import UiDateRangePicker from "@dpa-id-components/ui-date-range-picker";
import "@dpa-id-components/ui-date-range-picker/dist/UiDateRangePicker.css";
import { createDatePickerProps, formatDateRange } from "./lib/createDatePicker";
import { useAdvancedFullTextFilters } from "./advancedFullTextFilters.utils";
import useMatomoEvent from "@/composition/useMatomoEvent";

export default defineComponent({
  components: {
    UiDateRangePicker,
    UiInput
  },
  setup() {
    const { removeOrUpdateRules } = useAdvancedFullTextFilters();

    const dateRangeFilterRule = computed(() =>
      store.state.filterRules.filterRules.find(
        ({ type }) => type === "FILTER_CONTENT_CREATED"
      )
    );

    const setStartDateOffset = (val: Date | null) => {
      if (val !== null) {
        return setHours(val, 0);
      }
      return null;
    };

    const setEndDateOffset = (val: Date | null) => {
      if (val !== null) {
        return subMilliseconds(addDays(setHours(val, 0), 1), 1);
      }
      return null;
    };

    const handleDateRangeUpdate = ({
      startDate,
      endDate
    }: {
      startDate?: Date;
      endDate?: Date;
    }) => {
      removeOrUpdateRules({
        removeCondition:
          !startDate && !endDate && dateRangeFilterRule.value !== undefined,
        currentFilterRule: dateRangeFilterRule.value,
        updatedFilterRule: {
          type: "FILTER_CONTENT_CREATED",
          values: [setStartDateOffset(startDate), setEndDateOffset(endDate)]
        }
      });
      useMatomoEvent({
        category: "Advanced Search",
        action: "Select Date",
        name: "Publishing Date"
      });
    };

    const dateRange = computed({
      get: () => {
        let { from, to }: { from?: Date; to?: Date } = store.state.audioitems;

        if (dateRangeFilterRule.value) {
          [from, to] = dateRangeFilterRule.value.values;
        }

        return { startDate: from, endDate: to };
      },
      set({ startDate, endDate }) {
        handleDateRangeUpdate({ startDate, endDate });
      }
    });

    return {
      dateRange,
      datePickerProps: createDatePickerProps(),
      formatDateRange
    };
  }
});
