





















































import { computed, defineComponent, ref } from "@/composition-api";
import difference from "lodash.difference";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiIconInfo from "@/assets/vectors/icons/info.svg?inline";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiIconPlusCircle from "@/assets/vectors/icons/plus-circle.svg?inline";
import AdvancedFilterLayout from "./AdvancedFilterLayout.vue";
import useFilterSearchFields from "./useFilterSearchFields";
import {
  useAdvancedFullTextFilters,
  getIsTextComplete,
  getWordsOrPhrases,
  formatWordsOrPhrase
} from "./advancedFullTextFilters.utils";

export default defineComponent({
  components: {
    UiTooltip,
    UiIconInfo,
    AdvancedFilterLayout,
    UiButtonIcon,
    UiIconPlusCircle
  },
  setup(_, { emit }) {
    const { logicalOrFullTextFilterRules } = useFilterSearchFields();
    const { updateFilterRules } = useAdvancedFullTextFilters();

    const filterRulesToText = computed(() =>
      logicalOrFullTextFilterRules.value.length
        ? logicalOrFullTextFilterRules.value.map(({ values }) =>
            values
              .filter(value => !!value)
              .map(value => formatWordsOrPhrase(value))
              .join(", ")
          )
        : [""]
    );

    const shouldAddRow = ref(false);

    const getInputText = (inputTextList: string[]) =>
      difference(inputTextList, filterRulesToText.value)?.[0];

    const getUpdatePayload = (inputTextList: string[]) => {
      const inputText = getInputText(inputTextList);
      const inputTextIndex = inputTextList.indexOf(inputText);

      const filterRule = logicalOrFullTextFilterRules.value[inputTextIndex];
      const filterRules = filterRule ? [filterRule] : [];

      const isTextComplete = getIsTextComplete(inputText);

      return {
        inputText,
        filterRules,
        isTextComplete
      };
    };

    const searchInputList = computed({
      get: () => [
        ...filterRulesToText.value,
        ...(shouldAddRow.value ? [""] : [])
      ],
      set: (inputTextList: string[]) => {
        const { inputText, filterRules, isTextComplete } = getUpdatePayload(
          inputTextList
        );

        if (isTextComplete) {
          updateFilterRules({
            inputText,
            filterRules,
            isLogicalOr: true
          });
        }

        emit("update", { value: inputTextList });
      }
    });

    const shouldUpdateFilterRules = (inputText: string) => {
      const wordsOrPhrases = getWordsOrPhrases(inputText).filter(
        wordOrPhrase => !!wordOrPhrase
      );

      const isTextComplete = getIsTextComplete(inputText);

      return (wordsOrPhrases.length || !inputText) && isTextComplete;
    };

    const onSearchInput = (inputText: string, index: number) => {
      if (shouldUpdateFilterRules(inputText)) {
        shouldAddRow.value = false;
        const newValue = [...searchInputList.value];
        newValue.splice(index, 1, inputText);

        searchInputList.value = newValue;
      }
    };

    const isLastIndex = (index: number) =>
      index === searchInputList.value.length - 1;

    const addRow = () => {
      shouldAddRow.value = true;
    };

    return {
      searchInputList,
      onSearchInput,
      isLastIndex,
      addRow,
      shouldAddRow
    };
  }
});
