




















import { defineComponent } from "@/composition-api";
import UiMultiSlider from "@/components/ui/UiMultiSlider.vue";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useAudioLengthFormatter from "./audioLengthFormatter";

export default defineComponent({
  components: { UiMultiSlider },
  props: {
    hasLenghtLabel: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const {
      displayLabelOfSelectedRange,
      durationRange
    } = useAudioLengthFormatter();
    const label = displayLabelOfSelectedRange;
    const trackEvent = () => {
      useMatomoEvent({
        category: "Advanced Search",
        action: "Drag Length Sliders",
        name: "Length"
      });
    };

    return { label, trackEvent, durationRange };
  }
});
