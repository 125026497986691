




























import { computed, defineComponent } from "@/composition-api";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiIconInfo from "@/assets/vectors/icons/info.svg?inline";
import AdvancedFilterLayout from "./AdvancedFilterLayout.vue";
import useFilterSearchFields from "./useFilterSearchFields";
import {
  getTextRepresentation,
  useAdvancedFullTextFilters,
  getIsTextComplete
} from "./advancedFullTextFilters.utils";

export default defineComponent({
  components: { UiTooltip, UiIconInfo, AdvancedFilterLayout },
  setup(_, { emit }) {
    const { logicalNotFullTextFilterRules } = useFilterSearchFields();
    const { updateFilterRules } = useAdvancedFullTextFilters();

    const filterRulesToText = computed(() =>
      getTextRepresentation(logicalNotFullTextFilterRules.value)
    );

    const searchInput = computed({
      get: () => filterRulesToText.value,
      set: (inputText: string) => {
        if (getIsTextComplete(inputText)) {
          updateFilterRules({
            inputText,
            filterRules: logicalNotFullTextFilterRules.value,
            exclude: true
          });

          emit("update", { value: inputText });
        }
      }
    });

    return { searchInput };
  }
});
