interface Field {
  checked?: boolean;
  value?: any;
  label?: any;
}

/* Utils to be used in other advance filters */

export const getAreAllFieldsChecked = (fields: Field[]) => {
  const numberOfFields = fields.length;
  const checkedFields = fields.filter(({ checked }) => checked);

  return numberOfFields === checkedFields.length;
};

export const getCheckedValues = (fields: Field[]) => {
  if (getAreAllFieldsChecked(fields)) return [];

  return fields.filter(field => field.checked).map(field => field.value);
};
